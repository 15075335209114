@import "../../../styles/base.scss";

.container {
  margin: 0 auto 30px;
  width: 100%;
  padding: 5% 20%;
  position: relative;
  z-index: 1;
  overflow: hidden;
  // background: #FFFFFF;

  &::before {
    content: "";
    position: absolute;
    width: 1980.61px;
    height: 934.83px;
    left: 209.32px;
    top: 645.43px;

    background: #fff1ce;
    filter: blur(1000px);
    transform: rotate(35.23deg);
  }
}

.business--faq--container {
  margin: 0 auto 30px;
  width: 100%;
  padding: 5% 20% !important;
  position: relative;
  z-index: 1;
  overflow: hidden;

  &::before {
    content: "";
    position: absolute;
    width: 1980.61px;
    height: 150.83px;
    left: 0;
    top: 515.43px;
    background: #fff1ce;
    filter: blur(100px);
    transform: rotate(35.23deg);
  }
}
.header__details {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  div {
    width: 868px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    h3 {
      font-style: normal;
      font-weight: 700;
      font-size: 56px;
      line-height: 74px;
      /* or 132% */

      text-align: center;

      color: #ffffff;
    }

    p {
      font-style: normal;
      font-weight: 450;
      font-size: 24px;
      line-height: 36px;
      /* or 150% */

      text-align: center;

      margin-top: 24px;
      color: #f4f4f4;
    }
  }

  img {
    margin-top: -50px;
    width: 50%;
    height: 50%;
  }
}

.faq--tabs {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 30px;
  margin-top: 84px;
  position: relative;
  z-index: 3;
  padding: 0px 30px;

  &__active {
    background: #ffffff;
    border: 1px solid #9b0006;
    box-sizing: border-box;
    border-radius: 20px;
    height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 15px;
    cursor: pointer;
    h3 {
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 36px;
      text-align: center;
      color: #000000;
    }
  }

  &__title {
    background: #ffffff;
    border: 1px solid rgba(57, 65, 73, 0.2);
    box-sizing: border-box;
    border-radius: 20px;
    height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 15px;
    cursor: pointer;
    h3 {
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 36px;
      /* identical to box height, or 150% */

      text-align: center;

      /* gray/2 */

      color: #969696;
    }
  }
  // &__title:nth-child(1),
  // &__active:nth-child(1) {
  //   width: 161px;
  // }
  &__title:nth-child(1),
  &__active:nth-child(1) {
    width: 276px;
  }
  &__title:nth-child(2),
  &__active:nth-child(2) {
    width: 276px;
  }
  &__title:nth-child(3),
  &__active:nth-child(3) {
    width: 276px;
  }
  &__title:nth-child(4),
  &__active:nth-child(4) {
    width: 263px;
  }
}

.header__details,
.header__subtext {
  margin-bottom: 3%;
}

.header__details {
  text-align: center;

  .header__title {
    font-weight: bold;
    font-size: 4rem;
    line-height: 4rem;
  }
  .header__subtext {
    font-size: 2rem;
    line-height: 2rem;
  }
}

.border__light,
.border__red {
  border: 1px solid rgba(57, 65, 73, 0.2);
  .faq__title {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 31px;
    color: #000000;
  }
}

.faq__answer,
.faqs__contact {
  line-height: 2rem;
}

.faq__contact span,
.faq__title {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 31px;
  /* identical to box height, or 153% */

  color: #000000;
}

.faq__box {
  box-sizing: border-box;
  border-radius: 15px;
  padding: 1% 3%;
  margin-bottom: 3%;
  position: relative;
  z-index: 2;
  background: #ffffff;
}

.faq__question__bar {
  display: flex;
  justify-content: space-between;
  cursor: pointer;

  .faq__title {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 31px;
    /* identical to box height, or 153% */

    color: #000000;
  }
}

.faq__answer {
  font-size: 18px;
  opacity: 0.5;
}

.faqs__contact {
  font-weight: bold;
  opacity: 0.6;
}

@media screen and (max-width: 600px) {
  .header__details {
    width: 100%;
    div {
      width: 343px;
      h3 {
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        line-height: 42px;
        text-align: left;
      }
      p {
        font-style: normal;
        font-weight: 450;
        font-size: 16px;
        line-height: 24px;
        text-align: left;
      }
    }
  }

  .border__light,
  .border__red {
    .faq__question__bar {
      .faq__title {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
      }
    }
    .faq__answer {
      font-style: normal;
      font-weight: 450;
      font-size: 14px;
      line-height: 130%;
    }
  }
  .faq--tabs {
    margin-bottom: 71px;
    flex-wrap: wrap;
    gap: 15px;
    width: 100%;
    padding: 0px;
    &__active,
    &__title {
      h3 {
        font-size: 14px;
        line-height: 24px;
      }
    }

    &__title:nth-child(1),
    &__active:nth-child(1) {
      order: 1;
      
    }
    &__title:nth-child(2),
    &__active:nth-child(2) {
      order: 1;
      width: 151px;
    }
    &__title:nth-child(3),
    &__active:nth-child(3) {
      order: 1;
      width: 144px;
    }
    &__title:nth-child(4),
    &__active:nth-child(4) {
      order: 1;
      width: 151px;
    }
  }

  .container,
  .business--faq--container {
    padding: 0px !important;
    &::before {
      display: none;
    }
  }
}
@media (max-width: $break-point-xl) {
  .container,
  .business--faq--container {
    width: 85%;
    padding-top: 0;
  }

  .header__details {
    flex-direction: column;
    height: 100%;
    margin-top: 20%;

    div {
      margin-bottom: 0;
      margin-right: 0;
      margin-top: 40px;

      h3 {
        font-size: 1.5rem;
        line-height: 2rem;
      }
    }

    img {
      margin-top: -100px;
      height: 100%;
      width: 100%;
    }
  }

  // .header__details {
  //   .header__title {
  //     font-size: 2rem;
  //     line-height: 2rem;
  //   }

  //   .header__subtext {
  //     font-size: 1rem;
  //   }
  // }

  .faqs__contact {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 15% 0;
  }

  .contact__box {
    .container {
      width: 90%;
    }
  }
}
