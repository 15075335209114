@import "../../styles/base.scss";

.filled--large {
  width: 100%;
}

.filled,
.faint,
.small--ghost,
.small--filled,
.large--ghost,
.large--filled,
.medium--ghost,
.medium--filled {
  color: #fff;
  cursor: pointer;
  border: none;
  background-color: $primary-color;
  border-radius: 6px;
  font-weight: 400;
  font-size: 16px;
  height: 54px;
  text-align: center;
  // width: 100%;
  &:hover {
    // background-color: $primary-color-dark;
    transform: translate(0, 3px);
    transition: transform ease 0.35s;
  }

  &:disabled {
    cursor: not-allowed;
  }
}

.small--filled {
  padding: 12px 20px;
  font-size: 12px;
  width: 100%;
  font-size: 20px;
  font-weight: 500;
}

.small--ghost,
.medium--ghost,
.large--ghost {
  border: 1px solid $primary-color;
  color: $primary-color;
  background-color: transparent;
  text-align: center;
  width: 149px;
}

.medium--filled {
  padding: 12px 10px;
  font-size: 16px;
  width: 149px;
  text-align: center;
}

.large--filled {
  padding: 16px 48px;
  font-size: 18px;
  text-align: center;
}

.filter--button {
  background: #ffffff;
  border-radius: 8px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.2px;
  width: 225px;
  height: 56px;
  color: #1e1305;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.recording--filled {
  display: flex;
  align-items: center;
  gap: 5px;
}

.faint {
  background: rgba(235, 41, 49, 0.4);
  border-radius: 4px;
}

@media screen and (max-width: 600px) {
  .small--filled {
    width: 100%;
  }
}
